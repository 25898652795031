import { BaseLayout } from "@components/layout/BaseLayout";
import { Certificates, Datasheets, IsometricDrawing, Procedures, Settings } from "./features";
import { DatabaseCheck, LoginCheck } from "@components/guards";
import { Login, Logout, ResetPassword, ScanBadge } from "./features/login";
import { Navigate, Route, Routes } from "react-router-dom";
import { NotFound } from "@components";
import { RoleBasedInterface } from "features/rbi";

import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./css/bootstrap.scss";
import "./css/style.scss";

export default () => (
  <Routes>
    <Route element={<DatabaseCheck />}>
      <Route element={<BaseLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/login/scan" element={<ScanBadge />} />
        <Route path="/login/reset" element={<ResetPassword />} />
        <Route path="/logout" element={<Logout />} />

        <Route element={<LoginCheck />}>
          <Route path="/" element={<Navigate to="/certificates" />} />
          <Route path="/certificates" element={<Certificates />} />
          <Route path="/procedures" element={<Procedures />} />
          <Route path="/datasheets" element={<Datasheets />} />
          <Route path="/rbi/*" element={<RoleBasedInterface />} />
          <Route path="/drawing/:id" element={<IsometricDrawing />} />
          <Route path="/settings" element={<Settings />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  </Routes>
);
