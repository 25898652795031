import { Outlet } from "react-router";
import { useVersion } from "@queries/version.queries";

export function DatabaseCheck() {
  const version = useVersion();

  if (version.isLoading) {
    return <p>Loading...</p>;
  } else if (version.isError) {
    return <p>Failed to connect to database.</p>;
  }

  if (version.data && (!version.data.coordinatorVersionOK || !version.data.qualifierVersionOK)) {
    return (
      <div className="row justify-content-center mt-5">
        <div className="col-auto text-center mt-5">
          <h4>Database version not supported</h4>
          <p>Please update your database(s) to a newer version</p>
          <table className="table table-bordered mt-4">
            <thead className="table-light">
              <tr>
                <th>Database</th>
                <th>Current Version</th>
                <th>Minimum Version</th>
              </tr>
            </thead>
            <tbody>
              {version.data.qualifierVersion && (
                <tr>
                  <td>Welding Qualifier</td>
                  <td className={version.data.qualifierVersionOK ? "" : "text-danger"}>
                    {version.data.qualifierVersion}
                  </td>
                  <td>{version.data.qualifierMinVersion}</td>
                </tr>
              )}
              {version.data.coordinatorVersion && (
                <tr>
                  <td>Welding Coordinator</td>
                  <td className={version.data.coordinatorVersionOK ? "" : "text-danger"}>
                    {version.data.coordinatorVersion}
                  </td>
                  <td>{version.data.coordinatorMinVersion}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return <Outlet />;
}
