import { faChevronDown, faChevronUp, faFileContract, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProcedureSummary } from "../types/ProcedureSummary";
import { ServiceClient } from "@utils";
import { SignatureEntry } from "@components/record/SignatureEntry";
import { useGetRecordAttachments, useGetRecordSignatures } from "@queries/record.queries";
import { useState } from "react";

export function ProcedureCard(props: { procedure: ProcedureSummary }) {
  const { procedure } = props;
  const [expanded, setExpanded] = useState(false);

  const signatures = useGetRecordSignatures(procedure.recordId);
  const attachments = useGetRecordAttachments(procedure.recordId);
  const showSignatures = signatures.isSuccess && signatures.data.length > 0;
  const showAttachments = attachments.isSuccess && attachments.data.length > 0;
  const showExpandButton = showSignatures || showAttachments;

  function onViewProcedure(e: React.MouseEvent) {
    e.preventDefault();
    ServiceClient.redirectWithAuth(`procedures/${procedure.recordId}`);
  }

  function downloadAttachment(e: React.MouseEvent, attachmentId: string) {
    e.preventDefault();
    ServiceClient.redirectWithAuth(`record/${procedure.recordId}/attachments/${attachmentId}`);
  }

  return (
    <div className="card-text detail">
      <div>
        <div>
          <FontAwesomeIcon icon={faFileContract} />
          <a onClick={onViewProcedure} title="View procedure" className="ms-2">
            {procedure.recordNumber}
          </a>
          <span className="testDate">
            {procedure.company && <span>{procedure.company}</span>}
            {procedure.division && <span> ({procedure.division})</span>}
          </span>
        </div>
        <div>
          {procedure.processes && <span>{procedure.processes}</span>}{" "}
          {procedure.matProductForms && <span>({procedure.matProductForms})</span>}
          {showExpandButton && (
            <div className="signatureToggle">
              <a className="link-primary ms-auto" onClick={() => setExpanded((prev) => !prev)}>
                <div>
                  {expanded ? "Hide" : "Show more"}
                  <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} className="ms-2" />
                </div>
              </a>
            </div>
          )}
          {expanded && (
            <div className="d-flex flex-column flex-sm-row flex-wrap justify-content-between mb-3">
              <div className="mt-3">
                {showAttachments && (
                  <>
                    <div className="fw-bold">Attachments</div>
                    <ul className="list-unstyled mt-0">
                      {attachments.data.map((attachment, index) => (
                        <li key={index}>
                          <FontAwesomeIcon icon={faPaperclip} size="xs" />
                          <a
                            href="#"
                            onClick={(e) => downloadAttachment(e, attachment.attachmentId)}
                            title="Download Attachment"
                            className="ms-2"
                          >
                            {attachment.fileName}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
              <div className="mt-3">
                {showSignatures && (
                  <>
                    <div className="fw-bold">Signatures</div>
                    {signatures.data.map((signature, index) => (
                      <SignatureEntry type="Record" key={index} signature={signature} />
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
