import mainLogo from "assets/logo.png";
import React, { useEffect } from "react";
import twiLogo from "assets/twilogo.png";
import { faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HamburgerMenu } from "./HamburgerMenu";
import { Link } from "react-router-dom";
import { ServiceClient } from "@utils";
import { useConfiguration } from "@queries/configuration.queries";
import { useGetReferenceDocuments } from "@queries/record.queries";
import {
  faFile,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileImage,
  faFileVideo,
  faFileAudio,
  faFileArchive,
  faFileCode,
  faFileAlt,
} from "@fortawesome/free-regular-svg-icons";

export function Header() {
  const configuration = useConfiguration();
  const references = useGetReferenceDocuments();

  const hasQualifier = configuration.data?.qualifier;
  const hasCoordinator = configuration.data?.coordinator;
  const hasReferenceDocs = references.data && references.data.length > 0;
  const isInspector = configuration.data?.coordinator?.isInspector;
  const isLoggedIn = ServiceClient.isAuthenticated();

  useEffect(() => {
    window.addEventListener("scroll", toggleHeader);
    return () => {
      window.removeEventListener("scroll", toggleHeader);
    };
  }, []);

  const toggleHeader = () => {
    const sticky = document.getElementsByClassName("header-sticky")[0];
    const scroll = window.scrollY;
    const headerHeight = 180;

    if (window.innerWidth >= 320) {
      if (scroll < headerHeight) {
        sticky.classList.remove("is-sticky");
      } else {
        sticky.classList.add("is-sticky");
      }
    }
  };

  function onGetReference(e: React.MouseEvent, referenceId: string) {
    e.preventDefault();
    ServiceClient.redirectWithAuth(`references/${referenceId}`);
  }

  function onLogout(e: React.MouseEvent) {
    e.preventDefault();
    ServiceClient.logout();
  }

  function getFileNameWithoutExtension(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, "");
  }

  return (
    <header>
      <div
        id="header"
        className="header-area header-sticky bg-img space__inner--y40 space__bottom--r120 background-repeat--x background-color--dark d-none d-lg-block"
      >
        <div className="header-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-8"></div>
              <div className="col-lg-4 header-links">
                <div className="header-top-info text-right">
                  {isLoggedIn && hasReferenceDocs && (
                    <div className="dropdown">
                      <a href="#" className="header-top-info__link">
                        <span>
                          References <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </a>
                      <div className="dropdown-content">
                        <ul>
                          {references.data.map((doc: { fileName: string; fileType: number }, index: number) => (
                            <li key={index}>
                              {doc.fileType === 0 && <FontAwesomeIcon icon={faFile} />}
                              {doc.fileType === 1 && <FontAwesomeIcon icon={faFilePdf} />}
                              {doc.fileType === 2 && <FontAwesomeIcon icon={faFileWord} />}
                              {doc.fileType === 3 && <FontAwesomeIcon icon={faFileExcel} />}
                              {doc.fileType === 4 && <FontAwesomeIcon icon={faFileImage} />}
                              {doc.fileType === 5 && <FontAwesomeIcon icon={faFileVideo} />}
                              {doc.fileType === 6 && <FontAwesomeIcon icon={faFileAudio} />}
                              {doc.fileType === 7 && <FontAwesomeIcon icon={faFileArchive} />}
                              {doc.fileType === 8 && <FontAwesomeIcon icon={faFileCode} />}
                              {doc.fileType === 9 && <FontAwesomeIcon icon={faFileAlt} />}
                              <span> </span>
                              <a href="#" onClick={(e) => onGetReference(e, doc.fileName)} title="Click to View">
                                {getFileNameWithoutExtension(doc.fileName)}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                  {isLoggedIn && (
                    <>
                      <Link className="header-top-info__link" to="/settings">
                        <span>Settings</span>
                      </Link>
                      <a className="header-top-info__link" href="#" onClick={onLogout}>
                        <span>Logout</span>
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="menu-bar position-relative">
          <div className="container">
            <div className="row">
              <div>
                <div className="menu-bar-wrapper background-color--default space__inner--x35">
                  <div className="menu-bar-wrapper-inner">
                    <div>
                      <div className="header-right sticky">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-lg dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FontAwesomeIcon icon={faBars} />
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end">
                            {hasCoordinator && hasQualifier && (
                              <>
                                <li>
                                  <Link className="dropdown-item" to="/certificates">
                                    Certificates
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="/procedures">
                                    Procedures
                                  </Link>
                                </li>
                                {isInspector && (
                                  <li>
                                    <Link className="dropdown-item" to="/datasheets">
                                      {`${configuration.data?.datasheetName ?? "Datasheet"}s`}
                                    </Link>
                                  </li>
                                )}
                                {!isInspector && (
                                  <li>
                                    <Link className="dropdown-item" to="/rbi">
                                      Role Based Interface
                                    </Link>
                                  </li>
                                )}
                                <li>
                                  <hr className="dropdown-divider" />
                                </li>
                              </>
                            )}
                            {hasReferenceDocs &&
                              references.data.map((doc: { fileName: string; fileType: number }, index: number) => (
                                <li key={index}>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={(e) => onGetReference(e, doc.fileName)}
                                    title="Click to View"
                                  >
                                    {doc.fileType === 0 && <FontAwesomeIcon icon={faFile} />}
                                    {doc.fileType === 1 && <FontAwesomeIcon icon={faFilePdf} />}
                                    {doc.fileType === 2 && <FontAwesomeIcon icon={faFileWord} />}
                                    {doc.fileType === 3 && <FontAwesomeIcon icon={faFileExcel} />}
                                    {doc.fileType === 4 && <FontAwesomeIcon icon={faFileImage} />}
                                    {doc.fileType === 5 && <FontAwesomeIcon icon={faFileVideo} />}
                                    {doc.fileType === 6 && <FontAwesomeIcon icon={faFileAudio} />}
                                    {doc.fileType === 7 && <FontAwesomeIcon icon={faFileArchive} />}
                                    {doc.fileType === 8 && <FontAwesomeIcon icon={faFileCode} />}
                                    {doc.fileType === 9 && <FontAwesomeIcon icon={faFileAlt} />}
                                    <span> </span>
                                    {getFileNameWithoutExtension(doc.fileName)}
                                  </button>
                                </li>
                              ))}
                            {hasReferenceDocs && (
                              <li>
                                <hr className="dropdown-divider" />
                              </li>
                            )}
                            <li>
                              <Link className="dropdown-item" to="/settings">
                                Settings
                              </Link>
                            </li>
                            <li>
                              <button className="dropdown-item" type="button" onClick={onLogout}>
                                Log out
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div>
                        <div className="product-title d-flex align-items-center">
                          <span className="me-auto">
                            <img src={mainLogo} />
                            Waypoint
                          </span>
                          <img src={twiLogo} className="twilogo" />
                        </div>
                      </div>
                      <div className="no-sticky">
                        <div className="header-top-info text-right">
                          {isLoggedIn && hasReferenceDocs && (
                            <div className="dropdown">
                              <a href="#" className="header-top-info__link no-change">
                                <span>
                                  References <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                              </a>
                              <div className="dropdown-content">
                                <ul>
                                  {references.data.map((doc: { fileName: string; fileType: number }, index: number) => (
                                    <li key={index}>
                                      {doc.fileType === 0 && <FontAwesomeIcon icon={faFile} />}
                                      {doc.fileType === 1 && <FontAwesomeIcon icon={faFilePdf} />}
                                      {doc.fileType === 2 && <FontAwesomeIcon icon={faFileWord} />}
                                      {doc.fileType === 3 && <FontAwesomeIcon icon={faFileExcel} />}
                                      {doc.fileType === 4 && <FontAwesomeIcon icon={faFileImage} />}
                                      {doc.fileType === 5 && <FontAwesomeIcon icon={faFileVideo} />}
                                      {doc.fileType === 6 && <FontAwesomeIcon icon={faFileAudio} />}
                                      {doc.fileType === 7 && <FontAwesomeIcon icon={faFileArchive} />}
                                      {doc.fileType === 8 && <FontAwesomeIcon icon={faFileCode} />}
                                      {doc.fileType === 9 && <FontAwesomeIcon icon={faFileAlt} />}
                                      <span> </span>
                                      <a
                                        href="#"
                                        onClick={(e) => onGetReference(e, doc.fileName)}
                                        title="Click to View"
                                      >
                                        {getFileNameWithoutExtension(doc.fileName)}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )}
                          {isLoggedIn && (
                            <a className="header-top-info__link no-change" href="#" onClick={onLogout}>
                              <span>Logout</span>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="header-condensed">
        <span className="product-title">
          <img src={mainLogo} />
          Waypoint
        </span>
        <span className="header-right">{isLoggedIn && <HamburgerMenu />}</span>
      </div>
    </header>
  );
}
