import { InputHTMLAttributes } from "react";
import { useHeatNumbers } from "@queries/menu.queries";

type HeatNumberLookupProps = InputHTMLAttributes<HTMLInputElement> & {
  classification: string;
};

export function HeatNumberLookup({ classification, ...props }: HeatNumberLookupProps) {
  const lookup = useHeatNumbers(classification);
  const key = `heatNumbers_${classification}`;

  return (
    <>
      <input type="text" className="form-select" list={key} {...props} />
      <datalist id={key}>
        {lookup.data?.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
    </>
  );
}
